const uspsTrackingUrl = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels=';
const fedexTrackingUrl = 'https://www.fedex.com/fedextrack/?trknbr=';
const upsTrackingUrl = 'https://www.ups.com/track?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum='

const amazonMarketplaceUrl = 'https://sellercentral.amazon.com/orders-v3/order/';
const walmartMarketplaceUrl = 'https://seller.walmart.com/orders/manage-orders?orderGroups=All&poNumber=';
const ebayMarketplaceUrl = 'https://www.ebay.com/sh/ord/details?item=';

const inktasticCartUrl = 'https://custom.inktastic.com/cart.php?new_cart_id=';

function generateLabelForBuyShipping(shipStationShipment) {
	if (shipStationShipment.serviceCode.includes('usps_')) {
		return uspsTrackingUrl + shipStationShipment.trackingNumber;
	}

	if (shipStationShipment.serviceCode.includes('fedex_')) {
		return fedexTrackingUrl + shipStationShipment.trackingNumber;
	}

	if (shipStationShipment.serviceCode.includes('ups_')) {
		return upsTrackingUrl + shipStationShipment.trackingNumber;
	}

	return null;
}

export const generateTrackingUrl = (shipmentDoc) => {
	switch (shipmentDoc.carrier) {
		case 'USPS':
			return uspsTrackingUrl + shipmentDoc.trackingNumber;
		case 'FEDEX':
			return fedexTrackingUrl + shipmentDoc.trackingNumber;
		case 'UPS':
			return upsTrackingUrl + shipmentDoc.trackingNumber;
		default:
			return null;
	}
}

export const generateMarketplaceUrl = (shipmentDoc) => {
	switch (shipmentDoc.marketplace) {
		case 'Amazon':
			return `${amazonMarketplaceUrl}${shipmentDoc.marketplaceOrderId}`;
		case 'eBay':
			return `${ebayMarketplaceUrl}${shipmentDoc.marketplaceOrderId}`;
		case 'Walmart':
			return `${walmartMarketplaceUrl}${shipmentDoc.marketplaceOrderId}`;
		default:
			return null;
	}
}

export const generateInktasticCartUrl = (shipmentDoc) => {
	return `${inktasticCartUrl}${shipmentDoc.inktasticOrderId}`;
}
