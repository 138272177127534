import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const VoidModal = (props) => {
    const [voiding, setVoiding] = useState(false);

    const handleVoid = async () => {
        setVoiding(true);

        const voidLabelCallableFunction = httpsCallable(getFunctions(), 'voidLabel');
        try {
            await voidLabelCallableFunction({ trackingNumber: props.trackingNumber });
            props.onSuccess();
        } catch (error) {
            
        } finally {
            props.onHide();
            setVoiding(false);
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide} backdrop="static" centered>
            <Modal.Header closeButton={!voiding}>
                <Modal.Title>Void Label</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to void this label?</Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="secondary" disabled={voiding}>
                    No
                </Button>
                <Button variant="warning" onClick={handleVoid} disabled={voiding}>
                    {voiding ? 'Voiding...' : 'Yes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
