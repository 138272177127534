import { createTheme } from '@mui/material/styles';

// Bootstrap Dark Theme
export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		background: {
		default: '#343a40', // Bootstrap dark background color
		paper: '#343a40', // Bootstrap dark paper color
		},
		text: {
		primary: '#ffffff', // Bootstrap dark text color
		secondary: '#adb5bd', // Bootstrap dark secondary text color
		},
	},
	components: {
		MuiDataGrid: {
		styleOverrides: {
			root: {
			backgroundColor: '#343a40', // DataGrid background
			color: '#ffffff', // DataGrid text color
			},
			columnHeaders: {
			backgroundColor: '#495057', // Bootstrap dark header background
			color: '#ffffff', // Header text color
			},
			footerContainer: {
			backgroundColor: '#495057', // Footer background
			color: '#ffffff', // Footer text color
			},
			cell: {
			borderBottom: '1px solid #495057', // Cell border color
			},
		},
		},
	},
});
  
// Bootstrap Light Theme
export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		background: {
		default: '#ffffff', // Bootstrap light background color
		paper: '#ffffff', // Bootstrap light paper color
		},
		text: {
		primary: '#000000', // Bootstrap light text color
		secondary: '#6c757d', // Bootstrap light secondary text color
		},
	},
	components: {
		MuiDataGrid: {
		styleOverrides: {
			root: {
			backgroundColor: '#ffffff', // DataGrid background
			color: '#000000', // DataGrid text color
			},
			columnHeaders: {
			backgroundColor: '#f8f9fa', // Bootstrap light header background
			color: '#000000', // Header text color
			},
			footerContainer: {
			backgroundColor: '#f8f9fa', // Footer background
			color: '#000000', // Footer text color
			},
			cell: {
			borderBottom: '1px solid #dee2e6', // Cell border color
			},
		},
		},
	},
});
