import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Container, Row, Col, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PDFDocument } from 'pdf-lib';

const ScanSheet = () => {
  const [shipmentCount, setShipmentCount] = useState(0);
  const [scanForms, setScanForms] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [totalTrackingNumbers, setTotalTrackingNumbers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const db = getFirestore();
  const functions = getFunctions();

  useEffect(() => {
    const q = query(
      collection(db, 'shipments'),
      where('scanFormManifestId', '==', ''),
      where('shipDate', '==', moment(startDate).tz('America/New_York').startOf('day').format('YYYY-MM-DD')),
      where('voided', '==', false)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setShipmentCount(snapshot.size);
    });

    return () => unsubscribe();
  }, [db, startDate]);

  useEffect(() => {
    const easternTime = moment.tz("America/New_York");
    const startOfDay = easternTime.clone().startOf('day').toISOString();
    const endOfDay = easternTime.clone().endOf('day').toISOString();

    const scanFormsRef = query(
      collection(db, 'scan-forms'),
      where('createDate', '>=', startOfDay),
      where('createDate', '<=', endOfDay)
    );

    const unsubscribe = onSnapshot(scanFormsRef, (querySnapshot) => {
      const scanFormsData = [];
      let trackingNumbersCount = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.url) {
          scanFormsData.push({
            url: data.url,
            trackingNumbers: data.trackingNumbers || [],
          });
          trackingNumbersCount += (data.trackingNumbers || []).length;
        }
      });
      setScanForms(scanFormsData);
      setTotalTrackingNumbers(trackingNumbersCount);
    });

    return () => unsubscribe();
  }, [db]);

  const handleCloseShipments = async () => {
    setIsLoading(true);
    const generateSCANForm = httpsCallable(functions, 'generateSCANForm');
    try {
      const result = await generateSCANForm();
      console.log('SCAN form generated:', result);
    } catch (error) {
      console.error('Error generating SCAN form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = async () => {
    const pdfUrls = scanForms.map(form => form.url);
    const mergedPdf = await PDFDocument.create();

    for (const url of pdfUrls) {
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      pages.forEach(page => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

    window.open(mergedPdfUrl, '_blank');
  };

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <DatePicker showIcon selected={startDate} onChange={(date) => setStartDate(date)} />
          <p className="mb-0 d-inline">Open Shipments: {shipmentCount}</p>
          {shipmentCount > 0 && (
            <Button variant="primary" onClick={handleCloseShipments} className="ml-2" disabled={isLoading}>
              {isLoading ? 'Closing...' : 'Close Shipments'}
            </Button>
          )}
        </Col>
      </Row>
      {scanForms.length > 0 && (
        <>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Volume</th>
                  </tr>
                </thead>
                <tbody>
                  {scanForms.map((form, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{form.trackingNumbers.length}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td>{totalTrackingNumbers}</td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              {shipmentCount === 0 ? (
                <Button onClick={handlePrint} variant="primary">Print All</Button>
              ) : (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Close the remaining shipments before printing the SCAN form(s).
                    </Tooltip>
                  }>
                  <Button variant="danger">Print All</Button>
                </OverlayTrigger>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ScanSheet;
