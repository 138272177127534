import React from 'react';
import { auth as firebaseAuth } from './firebase';
import { signOut } from 'firebase/auth';
import withAuth from './WithAuthHOC';
import { NavDropdown } from 'react-bootstrap';
import { useTheme } from './ThemeContext';
import TotalShippedCountBadge from './TotalShippedCountBadge';


const UserDropdown = ({ auth }) => {
  const { user, isAdmin } = auth;
  const { theme, toggleTheme } = useTheme();

  const handleLogout = async () => {
    await signOut(firebaseAuth);
  };

  const dropdownTitle = (
    <span>
      <i className="bi bi-person-circle" style={{ marginRight: '5px' }}></i>
      {user.displayName ? user.displayName : user.email}{' '}
      <TotalShippedCountBadge />
    </span>
  );

  return (
    <NavDropdown
      id="nav-dropdown-dark-example"
      title={dropdownTitle}
      align={{ lg: 'end' }}
      autoClose="outside">
      <NavDropdown.Item href="#" onClick={toggleTheme}>
        {theme === 'light' ? (
          <><i className="bi bi-sun-fill"></i> Light Mode</>
        ) : (
          <><i className="bi bi-moon-stars-fill"></i> Dark Mode</>
        )}
      </NavDropdown.Item>
      <NavDropdown.Item href="#!" onClick={handleLogout}>
        <i className="bi bi-box-arrow-right"></i> Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default withAuth(UserDropdown);
