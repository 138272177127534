import React, { useState, useEffect } from 'react';
import { Form, Button, FormControl, Table, Alert, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import CopyToClipboardIcon from './CopyToClipboardIcon';
import { ShipmentRow } from './ShipmentRow';
import { firestore } from './firebase';
import { collection, query, getDocs, where } from 'firebase/firestore';

const OrderLookup = () => {
	let navigate = useNavigate();

	let { orderId } = useParams();

	useEffect(() => {
		if (orderId) {
		  console.log('Order ID:', orderId);
		  setSearchTerm(orderId); // This sets the searchTerm state for the form
		  getOrder(orderId); // Pass orderId directly to getOrder
		}
	}, [orderId]);

	const [searchTerm, setSearchTerm] = useState('');
	const [shipments, setShipments] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');

	const getOrder = async (id) => {
		const identifier = id || searchTerm;
		try {
			const inktasticOrderIdQuery = query(
				collection(firestore, 'shipments'),
				where('inktasticOrderId', '==', identifier))
			const marketplaceOrderIdQuery = query(
				collection(firestore, 'shipments'),
				where('marketplaceOrderId', '==', identifier))
			const trackingNumberQuery = query(
				collection(firestore, 'shipments'),
				where('trackingNumber', '==', identifier))
			const inktasticOrderIdSnapshot = await getDocs(inktasticOrderIdQuery);
			const marketplaceOrderIdSnapshot = await getDocs(marketplaceOrderIdQuery);
			const trackingNumberSnapshot = await getDocs(trackingNumberQuery);
			let shipments = [];
			inktasticOrderIdSnapshot.forEach((doc) => {
				shipments.push(doc.data());
			});
			marketplaceOrderIdSnapshot.forEach((doc) => {
				shipments.push(doc.data());
			});
			trackingNumberSnapshot.forEach((doc) => {
				shipments.push(doc.data());
			});
				
		  setShipments(shipments);
		  if (shipments.length === 0) {
			setErrorMessage("No shipments found for this order. Try looking it up in ShipStation.");
		  }
		} catch (error) {
		  console.error('Error:', error);
		  setErrorMessage("An error occurred");
		  setShipments([]);
		}
	};

	// Event handler for form submission
	const handleSearch = async (e) => {
		e.preventDefault(); // Prevent the default form submit action
		if (!searchTerm) {
		  setErrorMessage('Please enter an Order ID or Tracking Number.');
		  return;
		}
		setErrorMessage(''); // Clear any previous error message
		navigate(`/search/${searchTerm}`);
		await getOrder(); // Here, getOrder uses the searchTerm state
	};

	let shipmentsView = null;
	if (errorMessage.length > 0) {
		shipmentsView = (
			<Alert variant="danger" className="mt-3" style={{ maxWidth: '400px', overflow: 'hidden' }}>
				{errorMessage}
			</Alert>
		);
	} else {
		shipmentsView = (
			<>
			{(shipments.length > 0) && (
				<Card className="mt-3">
					<Card.Header>Order Info</Card.Header>
					<Card.Body>
						<Card.Title>Order IDs</Card.Title>
						<Card.Text>
							<div>
								<span>
									<span>Inktastic: </span>
									<a 
										href={`https://custom.inktastic.com/cart.php?new_cart_id=${shipments[0].inktasticOrderId}`}
										target="_blank" rel="noopener noreferrer"
									>
										{shipments[0].inktasticOrderId}
									</a>
								</span>
								<CopyToClipboardIcon text={shipments[0].inktasticOrderId} />
							</div>
						</Card.Text>
						<Card.Text>
							<div>
								<span>
									Marketplace: {shipments[0].marketplaceOrderId}
								</span>
								<CopyToClipboardIcon text={shipments[0].marketplaceOrderId} />
							</div>
						</Card.Text>
					</Card.Body>
				</Card>
			)}
			<Table responsive striped bordered hover size="sm" className="mt-3">
			{(shipments.length > 0) && (
				<>
					<thead>
						<tr>
							<th>Date/Time</th>
							<th>User</th>
							<th>Cost</th>
							<th>Expected Rates</th>
							<th>Zone</th>
							<th>Weight</th>
							<th>Tracking</th>
							<th>Status</th>
							<th>Shipping Platform</th>
							<th>Label</th>
						</tr>
					</thead>
				</>
			)}
			<tbody>	
			{shipments.map((shipment) => (
				<ShipmentRow shipment={shipment}></ShipmentRow>
			))}
			</tbody>
		</Table></>);
	}

	return (
		<>
		<div style={{ maxWidth: '400px', overflow: 'hidden' }}>
			<Form className="d-flex" onSubmit={handleSearch}>
				<FormControl
					type="text"
					placeholder="Order ID or Tracking Number"
					className="me-2"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)} />
				<Button variant="outline-success" type="submit">
					Search
				</Button>
			</Form>
		</div>
		{shipmentsView}
		</>
 	);
}

export default OrderLookup;
