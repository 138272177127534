import React from 'react';
import { Container } from 'react-bootstrap';
import FirebaseLogin from './FirebaseLogin';

function LoginForm() {
  return (
	<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
		<Container className="text-center py-3">
			<div style={{ maxWidth: '500px', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<img 
					src={`${process.env.PUBLIC_URL}/logo.png`} 
					alt="Logo" 
					style={{ marginBottom: '5px', maxWidth: '200px', height: 'auto' }} 
				/>
				<FirebaseLogin />
			</div>
		</Container>
	</div>
  );
}

export default LoginForm;
