import React from 'react';
import withAuth from './WithAuthHOC';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { Tabs, Tab, Container } from 'react-bootstrap';
import UsersList from './UsersList';
import OrderLookup from './OrderLookup';
import { useTheme } from './ThemeContext'; 
import { ShipmentsGrid } from './ShipmentsGrid';
import { FilterProvider } from './ShipmentsFilterContext';
import Settings from './Settings';


const TabsWithRoutes = ({ auth }) => {
  const { user, isAdmin } = auth;
  const { theme } = useTheme();

  let navigate = useNavigate();
  let location = useLocation();
  const getActiveKey = (pathname) => {
    if (pathname.startsWith('/search')) {
      return '/search'; // This ensures the Shipment Lookup tab is active for both /search and /search/:orderId
    }
    // Add more conditions as needed for other tabs
    return pathname;
  };

  // Handle tab selection and navigate to the selected tab's path
  const handleSelect = (key) => navigate(key);

  const shouldShowTabs = ['/leaders', '/shipments'].includes(location.pathname);
  
  return (
    <>
    {shouldShowTabs && (
      <Tabs activeKey={getActiveKey(location.pathname)} onSelect={handleSelect} className="pt-3">
        {isAdmin && <Tab eventKey="/leaders" title="Daily Leaderboard" />}
        <Tab eventKey="/shipments" title="Shipments" />
        {/* Add more tabs as needed */}
      </Tabs>
    )}
      {/* Define routes for tab content */}
      <Routes>
        {isAdmin ? (
          <><Route path="/leaders" element={
            <div style={{ maxWidth: '400px', overflow: 'hidden' }}>
              <Container fluid className="pt-3">
                <UsersList />
              </Container>
            </div>
          } />
          <Route path="/settings" element={
            <Container fluid className="pt-3">
              <Settings></Settings>
            </Container>
          } /></>
        ): (
          <Route path="/leaders" element={<Navigate replace to="/shipments" />} />
        )}
        <Route path="/search" element={
          <Container fluid className="pt-3">
            <OrderLookup />
          </Container>
        } />
        <Route path="/search/:orderId" element={
          <Container fluid className="pt-3">
            <OrderLookup />
          </Container>
        } />
        <Route path="/shipments" element={
          <Container fluid className="pt-3">
            <FilterProvider>
              <ShipmentsGrid />
            </FilterProvider>
          </Container>
        } />
        <Route path="/" element={<Navigate replace to="/shipments" />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default withAuth(TabsWithRoutes);
