import React, { useState } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboardIcon from './CopyToClipboardIcon';
import { find } from 'underscore';
import { useUsers } from './UsersContext'
import { generateTrackingUrl } from './utils';

export const ShipmentRow = ({ shipment }) => {
	const users = useUsers();
	const [showAdditionalTiers, setShowAdditionalTiers] = useState(false);

	const handleTier2Click = () => {
		setShowAdditionalTiers(!showAdditionalTiers);
	};

	function getSwiftShipUsername(shipment) {
		if (shipment.userId) {
			const userName = find(users, user => user.id === shipment.userId).name;
			return userName.charAt(0).toUpperCase() + userName.slice(1);
		}
	
		return (
			<div className="text-with-question">
			<span>API</span>
			<OverlayTrigger
				placement="top"
				overlay={
				<Tooltip id="tooltip-top">
					This shipment was created before SwiftShip implemented user tracking.
				</Tooltip>
			}>
				<button className="question-mark-btn" aria-label="More Info">
				<FontAwesomeIcon icon={faQuestionCircle} />
				</button>
			</OverlayTrigger>
			</div>
		);
	}

	return (
		<tr>
			<td>{formatDateAndTime(shipment.createDate)}</td>
			<td>{shipment.shipStationUser ? shipment.shipStationUser.name : getSwiftShipUsername(shipment)}</td>
			<td>${shipment.cost}</td>
			<td>
				{shipment.evsRates ? (
					<div>
						{showAdditionalTiers && (
							<div className="highlight-grey p-2">Tier 1: ${shipment.evsRates.tier1.toFixed(2)}</div>
					)}
					<div
						className={shipment.cost !== shipment.evsRates.tier2 ? 'highlight-red p-2' : 'highlight-green p-2'}
						onClick={handleTier2Click}
						style={{ cursor: 'pointer' }}
					>
						Tier 2: ${shipment.evsRates.tier2.toFixed(2)}
					</div>
					{showAdditionalTiers && (
						<>
						<div className="highlight-grey p-2">Tier 3: ${shipment.evsRates.tier3.toFixed(2)}</div>
						<div className="highlight-grey p-2">Tier 4: ${shipment.evsRates.tier4.toFixed(2)}</div>
						</>
					)}
					</div>
				) : 'N/A'}
			</td>
			<td>{shipment.zone ? shipment.zone : 'N/A'}</td>
			<td>
			{shipment.weightOunces ? `${Math.floor(shipment.weightOunces / 16)} lbs ${(shipment.weightOunces % 16).toFixed(1)} oz` : 'N/A'}
			</td>
			<td>
			<a href={generateTrackingUrl(shipment)} target="_blank" rel="noopener noreferrer">
				{shipment.trackingNumber}
			</a>
			<CopyToClipboardIcon text={shipment.trackingNumber} />
			</td>
			<td>{shipment.voided ? 'Voided @ ' + formatDateAndTime(shipment.voidDate) : 'Shipped'}</td>
			<td>
				{shipment.shipStationUser ? 'ShipStation' : 'SwiftShip'} {shipment.shippingLabelApi === 'evs' ? '(EVS)' : ''}
			</td>
			<td>
				{(shipment.labelURL && !shipment.voided) ? (
						<Button variant="primary" onClick={() => reprintLabel(shipment.labelURL)}><FontAwesomeIcon icon={faPrint} /> Reprint</Button>
				) : 'N/A'}
			</td>
		</tr>
	);
}

const reprintLabel = async (url) => {
	const pdfBytes = await fetch(url).then(res => res.arrayBuffer());
	const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
	const pdfURL = URL.createObjectURL(pdfBlob);

	window.open(pdfURL, '_blank');
}

function formatDateAndTime(timestamp) {
    const dateInPacificTime = moment.tz(timestamp, "America/Los_Angeles");
    const dateInEasternTime = dateInPacificTime.clone().tz("America/New_York");
    const formattedDateTime = dateInEasternTime.format('M/DD/YY hh:mm A');

    return formattedDateTime;
}
