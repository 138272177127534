import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { doc, updateDoc, getFirestore, getDoc } from 'firebase/firestore';
import { useToast } from './ToastContext';
import './Settings.css';

const Settings = () => {
    const [useEVS, setUseEVS] = useState(false);
	const { showToast, showSuccessToast } = useToast();

    useEffect(() => {
        const fetchSettings = async () => {
            const settingsRef = doc(getFirestore(), 'appSettings', 'settings');
            const docSnap = await getDoc(settingsRef);
            setUseEVS(docSnap.data().useEVS);
        };
        fetchSettings();
    }, []);

    const handleSave = async () => {
        try {
            const settingsRef = doc(getFirestore(), 'appSettings', 'settings');
            await updateDoc(settingsRef, { useEVS });
			showSuccessToast("Success", "Settings saved successfully!");
        } catch (error) {
			showToast("Error", "Error saving settings.");
        }
    };

    return (
        <div className="settings-container">
            <Form className="settings-form">
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                        type="switch"
                        label="Use EVS for USPS Shipments"
                        checked={useEVS}
                        onChange={(e) => setUseEVS(e.target.checked)}
                    />
                </Form.Group>
                <div className="form-actions">
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Settings;
