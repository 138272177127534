import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardIcon = ({ text }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setShow(true);
        setTimeout(() => setShow(false), 1000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
    <ContentCopyIcon 
          color='action'
          fontSize='small'
          onClick={copyToClipboard}
          ref={target}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        />
      <Overlay target={target.current} show={show} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default CopyToClipboardIcon;
