import React from 'react';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

function FirebaseLogin() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const login = async (e) => {
		e.preventDefault();
		try {
			let usernameOrEmail = email;
			if (!email.includes('inktastic.com')) {
				usernameOrEmail = email + '@inktastic.com';
			}
			await signInWithEmailAndPassword(auth, usernameOrEmail, password);
		} catch (error) {
			alert(error.message);
		}
	};

	return (
		<Form inline onSubmit={login}>
			<Form.Control
			style={{ marginBottom: '5px' }}
			placeholder="Email or Username"
			onChange={(e) => setEmail(e.target.value)}
			autoFocus={true}
			required
			/>
			<Form.Control
			style={{ marginBottom: '20px' }}
			type="password"
			placeholder="Password"
			onChange={(e) => setPassword(e.target.value)}
			required
			/>
			<Button type="submit">Login</Button>
		</Form>
	);
}

export default FirebaseLogin;
