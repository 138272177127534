import React from 'react';
import { useAuth } from './AuthContext';

const withAuth = WrappedComponent => {
  return props => {
    const { loading, ...rest } = useAuth();

    if (loading) {
		console.log('Loading...');
      return <div>Loading...</div>; // Or any loading indicator you prefer
    }

    // Spread the rest of the auth context props to the WrappedComponent
    return <WrappedComponent {...props} auth={rest} />;
  };
};

export default withAuth;
