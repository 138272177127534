import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  fontSize: '.9em', // Adjust the text size here
	},
  });

export const TierTooltip = ({ tiers, cost }) => {
	const tooltipContent = () => (
		<div>
		  <div>Tier 1: ${tiers.tier1.toFixed(2)}</div>
		  <div className={cost !== tiers.tier2 ? 'text-danger' : 'text-success'}>Tier 2: ${tiers.tier2.toFixed(2)}</div>
		  <div>Tier 3: ${tiers.tier3.toFixed(2)}</div>
		  <div>Tier 4: ${tiers.tier4.toFixed(2)}</div>
		</div>
	);

  return (
    <CustomTooltip title={<div>{tooltipContent()}</div>} arrow placement='right' >
      <div className={cost !== tiers.tier2 ? 'text-danger' : 'text-success'}>
		Tier 2: ${tiers.tier2.toFixed(2)}
	  </div>
    </CustomTooltip>
  );
};
