import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Sensitivity of Firebase Config
// Not Highly Sensitive: Firebase configuration details are considered not highly sensitive. This is because they are required to be included in client-side code (your web app) where they can be seen by anyone who inspects the web app's source code. These keys are designed to be publicly visible and do not grant direct access to your Firebase project.
// Limited Scope: The keys in the Firebase config are used to initialize your app's connection to Firebase services and do not alone provide the ability to access or modify your Firebase project's data. Access to data is controlled by your Firebase project's security rules.
const firebaseConfig = {
	apiKey: "AIzaSyB-0T838vhMS42OCiypPfUtn5dVOWujTzY",
	authDomain: "swiftship-7a91f.firebaseapp.com",
	projectId: "swiftship-7a91f",
	storageBucket: "swiftship-7a91f.appspot.com",
	messagingSenderId: "724309289444",
	appId: "1:724309289444:web:07a3c74b37de018abefff1",
	measurementId: "G-Z8Z96DDWT9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);
// Initialize Functions
const functions = getFunctions(app);
const auth = getAuth();

if (window.location.hostname === 'localhost') {
	connectFirestoreEmulator(firestore, 'localhost', 8081);
	connectFunctionsEmulator(functions, 'localhost', 5003);
	connectAuthEmulator(auth, 'http://localhost:9099');
}

export { firestore, functions, httpsCallable, auth };
