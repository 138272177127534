import React from 'react';
import downloadsList from './downloadsList.json'; // Adjust the import path as needed
import { Container, Card, ListGroup } from 'react-bootstrap';

const DownloadList = () => {
  return (
    <Container>
      <Card className="mt-3">
        <Card.Header as="h3">Releases</Card.Header>
        <ListGroup variant="flush">
            <ListGroup.Item >
              <a href={downloadsList.latestUpdateUrl} download>{`Apple Silicon v${downloadsList.version}`}</a>
            </ListGroup.Item>
        </ListGroup>
      </Card>
    </Container>
  );
};

export default DownloadList;
