import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './NavBar';
import LoginForm from './LoginForm';
import { useAuth } from './AuthContext';
import './App.css';
import TabsWithRoutes from './TabsWithRoutes';
import Downloads from './Downloads';
import { useTheme } from './ThemeContext'; 
import ScanSheet from './ScanSheet';

function App() {
  const { loading, user } = useAuth();
  const { theme } = useTheme();

  useEffect(() => {
    document.body.setAttribute('data-bs-theme', theme);
  }, [theme]);

  if (loading) {
    return
  }

  return (
    <Router>
      <div className="App">
        {user ? (
          <>
            <NavBar />
            <Routes>
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/scan-sheet" element={<ScanSheet />} />
              <Route path="*" element={<TabsWithRoutes />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
