import React from 'react';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment-timezone';
import { find } from 'underscore';
import CopyToClipboardIcon from './CopyToClipboardIcon';
import { generateInktasticCartUrl, generateMarketplaceUrl, generateTrackingUrl } from './utils';
import { TierTooltip } from './TierTooltip';

export const shipmentGridColumns = (onPrintClick, onVoidClick, users) => [
	{
		field: 'inktasticOrderId',
		headerName: 'Order ID',
		type: 'number',
		width: 120,
		renderCell: (params) => {
		return (
			<>
			<a href={generateInktasticCartUrl(params.row)} target="_blank" rel="noopener noreferrer">
				{params.value}
			</a>
			<CopyToClipboardIcon text={params.value} />
			</>
		);
		},
		sortable: false
	},
	{
		field: 'createDate',
		headerName: 'Create Date',
		width: 150,
		type: 'date',
		valueGetter: (params) => new Date(params),
		valueFormatter: (params) => {
			const date = moment(params).tz('America/New_York').format('M/DD/YY hh:mm A');
			return date;
		},
		sortable: false
		},
		{
		field: 'shipDate',
		headerName: 'Ship Date',
		width: 100,
		type: 'string',
		valueFormatter: (params) => {
			const date = moment(params).format('M/DD/YY');
			return date;
		},
		sortable: false
	},
	{
		field: 'promisedDeliveryDate',
		headerName: 'Promised Delivery Date',
		width: 200,
		type: 'string',
		valueFormatter: (params) => {
			console.log('promised date', params);
			if (params) {
				const date = moment(params).tz('America/Los_Angeles').format('M/DD/YY');
				return date + ' PDT';
			}

			return 'N/A';
		},
		sortable: false
	},
	{
		field: 'userId',
		headerName: 'User',
		width: 100,
		type: 'string',
		valueGetter: (params) => {
			const userId = params;
			const user = find(users, user => user.id === userId);
			if (user) {
				return user.name.charAt(0).toUpperCase() + user.name.slice(1);
			}
			return 'N/A';
		},
		sortable: false
	},
	{
		field: 'cost',
		headerName: 'Cost',
		width: 100,
		type: 'number',
		valueFormatter: (params) => `$${params}`,
		sortable: false
	},
	{
		field: 'evsRates',
		headerName: 'Expected Rates',
		width: 150,
		type: 'number',
		renderCell: (params) => {
			return params.row.evsRates ? (
				<TierTooltip tiers={params.value} cost={params.row.cost} />
			) : 'N/A';
		},
		sortable: false
	},
	{
		field: 'zone',
		headerName: 'Zone',
		width: 100,
		type: 'number',
		valueFormatter: (params) => params || 'N/A',
		sortable: false
	},
	{
		field: 'weightOunces',
		headerName: 'Weight',
		width: 150,
		type: 'number',
		valueFormatter: (params) => (
			params ? `${Math.floor(params / 16)} lbs ${(params % 16).toFixed(1)} oz` : 'N/A'
		),
		sortable: false
	},
	{
		field: 'trackingNumber',
		headerName: 'Tracking Number',
		type: 'number',
		width: 250,
		renderCell: (params) => {
			return (
				<>
				<a href={generateTrackingUrl(params.row)} target="_blank" rel="noopener noreferrer">
					{params.value}
				</a>
				<CopyToClipboardIcon text={params.value} />
				</>
			);
		},
		sortable: false
	},
	{
		field: 'marketplace',
		headerName: 'Marketplace',
		width: 100,
		type: 'string',
		sortable: false
	},
	{
		field: 'marketplaceOrderId',
		headerName: 'Marketplace ID',
		type: 'number',
		width: 200,
		renderCell: (params) => {
			return (
				<>
				<a href={generateMarketplaceUrl(params.row)} target="_blank" rel="noopener noreferrer">
					{params.value}
				</a>
				<CopyToClipboardIcon text={params.value} />
				</>
			);
		},
		sortable: false
	},
	{
		field: 'carrier',
		headerName: 'Carrier',
		width: 100,
		type: 'string',
		sortable: false
	},
	{
		field: 'service',
		headerName: 'Service',
		width: 150,
		type: 'string',
		sortable: false
	},
	{
		field: 'package',
		headerName: 'Package',
		width: 100,
		type: 'string',
		sortable: false
	},
	{
		field: 'labelURL',
		headerName: 'Reprint',
		type: 'actions',
		width: 50,
		renderCell: (params) => {
			return (
				<IconButton
				color="primary"
				onClick={() => onPrintClick(params.value)}
			>
				<PrintIcon />
			</IconButton>
			);
		},
		sortable: false
	},
	{
		field: 'void',
		headerName: 'Void',
		type: 'actions',
		width: 50,
		renderCell: (params) => {
			if (params.row.shippingLabelApi !== 'evs') {
				return <span>N/A</span>;
			}

			return !params.row.voided ? (
				<IconButton
				color="secondary"
				onClick={() => onVoidClick(params.row.trackingNumber)}
				>
				<BlockIcon />
				</IconButton>
			) : (
				<span className='text-danger'>Voided</span>
			);
		},
		sortable: false
	},
];
