import React, { useState, useEffect, useRef } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { DataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useTheme } from './ThemeContext'; 
import RefreshIcon from '@mui/icons-material/Refresh';
import { VoidModal } from './VoidModal';
import { lightTheme, darkTheme } from './GridThemes';
import { ShipmentsFilters } from './ShipmentsFilters';
import { useUsers } from './UsersContext';
import { shipmentGridColumns } from './ShipmentsGridColumns';
import { useFilters } from './ShipmentsFilterContext';

const searchClient = algoliasearch('7O9IX8R4LF', 'eec7325a4f066e03bc0dae5b74c929ad');
const index = searchClient.initIndex('shipments');

const reprintLabel = async (url) => {
	const pdfBytes = await fetch(url).then(res => res.arrayBuffer());
	const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
	const pdfURL = URL.createObjectURL(pdfBlob);

	window.open(pdfURL, '_blank');
}

export const ShipmentsGrid = () => {
	const users = useUsers();
  const { theme } = useTheme();
  const { filters } = useFilters();

  const [shipments, setShipments] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100); // Default page size
  const [totalHits, setTotalHits] = useState(0);
  const prevPageSizeRef = useRef(pageSize); // Use ref to track previous page size
  const gridContainerRef = useRef(null); // Ref to the grid container
  const [gridHeight, setGridHeight] = useState(0); // State to hold grid height
  const [gridTheme, setGridTheme] = useState(lightTheme);
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [trackingNumberToVoid, setTrackingNumberToVoid] = useState('');

  useEffect(() => {
    fetchShipments(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    setPage(0);
    fetchShipments(page, pageSize);
  }, [filters]);

  useEffect(() => {
    const calculateGridHeight = () => {
      if (gridContainerRef.current) {
        const offsetTop = gridContainerRef.current.offsetTop;
        setGridHeight(window.innerHeight - offsetTop - 12);
      }
    };

    calculateGridHeight();
    window.addEventListener('resize', calculateGridHeight);
    return () => window.removeEventListener('resize', calculateGridHeight);
  }, []);

  useEffect(() => {
    setGridTheme(theme === 'dark' ? darkTheme : lightTheme);
  }, [theme]);

  const fetchShipments = async (currentPage, currentPageSize) => {
    try {
      let searchOptions = {
        hitsPerPage: currentPageSize,
        page: currentPage,
      };

      searchOptions.filters = "";

      const baseFilters = [
        `inktasticOrderId:${filters.id}`,
        `marketplaceOrderId:${filters.id}`,
        `trackingNumber:${filters.id}`
      ].filter(term => filters.id.length > 0).join(' OR ');

      const additionalFiltersArray = Object.entries(filters)
        .filter(([key, value]) => value)
        .filter(([key, value]) => key !== 'id')
        .map(([key, value]) => {
          if (key === 'shipDateStart') {
            return `shipDateNumeric>=${parseInt(value.replace(/-/g, ''))}`;
          } else if (key === 'shipDateEnd') {
            return `shipDateNumeric<=${parseInt(value.replace(/-/g, ''))}`;
          } else if (key === 'overcharged' && value) {
            return `overcharged:true`;
          } else if (key === 'saturdayDelivery' && value) {
            return `saturdayDelivery:${value === 'yes' ? true : false}`;
          } else {
            return `${key}:${value}`;
          }
        });

      const additionalFilters = additionalFiltersArray.join(' AND ');

      searchOptions.filters = [baseFilters, additionalFilters].filter(Boolean).join(' AND ');

      console.log('Algolia Filters', searchOptions.filters);
      const { hits, nbHits } = await index.search('', searchOptions);
      setShipments(hits);
      setTotalHits(nbHits);
    } catch (error) {
      console.error('Error fetching shipments:', error);
    }
  };

  const handlePaginationModelChange = (model) => {
    if (model.pageSize !== prevPageSizeRef.current) {
      setPage(0); // Reset to first page when page size changes
    } else {
      setPage(model.page);
    }
    setPageSize(model.pageSize);
    prevPageSizeRef.current = model.pageSize;
  };

  const handleRefresh = () => {
    searchClient.clearCache();
    setPage(0);
    fetchShipments(0, pageSize);
  }

  const openVoidModal = (trackingNumber) => {
    setShowVoidModal(true);
    setTrackingNumberToVoid(trackingNumber);
  }

  const onVoidSuccess = () => {
    setShipments((prevRows) =>
      prevRows.map((row) =>
        row.trackingNumber === trackingNumberToVoid ? { ...row, voided: true } : row
      )
    );
  }

  return (
      <div>
        <ThemeProvider theme={gridTheme}>
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
            <Grid container spacing={2} alignItems="center" style={{ padding: 16 }}>
              <ShipmentsFilters/>
            </Grid>
            <IconButton
              color="primary"
              onClick={handleRefresh}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
          <div ref={gridContainerRef}>
          <DataGrid ref={gridContainerRef}
          disableColumnFilter
          style={{ height: gridHeight }} // Adjust height based on the window height and offsetTop
          rows={shipments}
          columns={shipmentGridColumns(reprintLabel, openVoidModal, users)}
          pagination
          paginationMode="server"
          rowCount={totalHits}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={handlePaginationModelChange} // Handle pagination model changes
          getRowId={(row) => row.objectID}
          pageSizeOptions={[25, 50, 100]} // Custom page size options
        />
          </div>
          
          </ThemeProvider>
        <VoidModal
          show={showVoidModal}
          onHide={() => setShowVoidModal(false)}
          onSuccess={onVoidSuccess}
          trackingNumber={trackingNumberToVoid}
        />
    </div>
  );
};
