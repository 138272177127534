import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';

const ToastContext = createContext(undefined);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = useCallback((header, body) => {
    setToast({ header, body, type: 'danger' });
    setTimeout(() => setToast(null), 5000);
  }, []);

  const showSuccessToast = useCallback((header, body) => {
    setToast({ header, body, type: 'success' });
    setTimeout(() => setToast(null), 5000);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, showSuccessToast }}>
      {children}
      <ToastContainer position="bottom-start" className="p-3">
        {toast && (
          <Toast onClose={() => setToast(null)} delay={5000} autohide bg={toast.type}>
            <Toast.Header>
              <strong className="me-auto">{toast.header}</strong>
            </Toast.Header>
            <Toast.Body>{toast.body}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
