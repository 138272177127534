import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { firestore } from "./firebase";
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from "./AuthContext";

export default function TotalShippedCountBadge() {
  const [ordersShipped, setOrdersShipped] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    const docRef = doc(firestore, 'users', user.uid);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setIsLoading(false);
      if (doc.exists()) {
        const data = doc.data();
        setOrdersShipped(data.ordersShipped);
      } else {
        // Handle the case where the document does not exist
        console.log("No such document!");
        setOrdersShipped('N/A');
      }
    }, (error) => {
      // Handle any errors
      console.error("Error fetching document:", error);
      setIsLoading(false);
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [user]);

  return (
    <>
      {!isLoading && ordersShipped > 0 && (
        <Badge bg="success">{ordersShipped}</Badge>
      )}
    </>
  );
}
