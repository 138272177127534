import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from './firebase'; // Adjust this import based on your setup
import _ from 'lodash';
import { ListGroup } from 'react-bootstrap';
import { AnimatePresence, motion } from 'framer-motion';

const UsersComponent = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const ordersShippedQuery = query(
      collection(firestore, 'users'),
      where('ordersShipped', '>', 0)
    );

    const unsubscribe = onSnapshot(ordersShippedQuery, (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sorting the users by ordersShipped
      const sortedUsers = usersData.sort((a, b) => {
        return b.ordersShipped - a.ordersShipped;
      });

      setUsers(sortedUsers);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      {/* <h3 className="mb-3">Daily Leaderboard</h3> */}
      <ListGroup>
        <AnimatePresence>
          {users.map((user, index) => (
            <motion.div
              key={user.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              layout
            >
              <ListGroup.Item>
                {index === 0 && '🏆 '}
                {_.capitalize(user.name)}: {user.ordersShipped}
              </ListGroup.Item>
            </motion.div>
          ))}
        </AnimatePresence>
      </ListGroup>
    </>
  );
};

export default UsersComponent;
