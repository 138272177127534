import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const UsersContext = createContext([]);

const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  const capitalizeName = (name) => {
    return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  useEffect(() => {
    const getUsers = async () => {
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      let usersList = usersSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          name: capitalizeName(data.name)
        };
      });
      usersList = usersList.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(usersList);
    };

    getUsers();
  }, []);

  return (
    <UsersContext.Provider value={users}>
      {children}
    </UsersContext.Provider>
  );
};

export { UsersProvider, UsersContext };

// Custom hook for consuming the context
export const useUsers = () => useContext(UsersContext);
